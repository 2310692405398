import { graphql, Link } from "gatsby";
import * as React from "react";
import { Pagination } from "../components/blog/Pagination";
import { HTMLContent } from "../components/common/Content";
import Layout from "../components/layout/Layout";
import Head from "../components/security/Head";
import Reporting from "../components/security/Reporting";

// props
interface Props {
  data: {
    cves: {
      edges: Array<{
        node: {
          frontmatter: Array<{
            title: string;
            slug: string;
            severity: string;
            publishedAt: string;
            description: string;
          }>;
          fields: {
            path: string;
          };
        };
      }>;
    };
  };
  pageContext: {
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
    totalCount: number;
  };
}

const Item = ({ cve }: any) => {
  const { cveData } = cve?.frontmatter;
  return (
    <article className="item py-6">
      <h2 className="is-size-3 has-text-weight-semibold">
        <Link className="has-text-black" to={cve.fields.path}>
          {cve.frontmatter.title}
        </Link>
      </h2>
      <div className="meta py-4">
        {cve.frontmatter.severity?.toUpperCase()} |{" "}
        {cve.frontmatter.date.toUpperCase()} |{" "}
        {cve.frontmatter.slug.toUpperCase()}
      </div>
      <HTMLContent
        content={cve.excerpt}
        className={"content markdown"}
      ></HTMLContent>
      <div>
        <Link className="button is-spring" to={cve.fields.path}>
          Read more
        </Link>
      </div>
    </article>
  );
};

// markup
const SecurityTemplate = (props: Props) => {
  return (
    <Layout className="security" seo={{ title: "Security Advisories" }}>
      <div className="container">
        <div className="security-content pb-4">
          <Head />
          <p className="links-animate">
            This page list the Project Reactor advisories. Additional
            information on other VMware Products can be found on the{" "}
            <a href="https://www.vmware.com/security/advisories.html">
              VMware Security Advisories
            </a>{" "}
            page.
          </p>
        </div>
        <div className="columns">
          <div className="column is-8">
            <div className="content security-content pb-6">
              <div>
                {props.data.cves.edges.map((cve: any, i: number) => {
                  return <Item cve={cve.node} key={`item${i}`} />;
                })}
              </div>
              <Pagination
                path={`/security/`}
                currentPage={props.pageContext.currentPage}
                postsPerPage={props.pageContext.limit}
                totalCount={props.pageContext.totalCount}
              />
            </div>
          </div>
          <div className="column is-4 pt-6 mt-4">
            <Reporting />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SecurityTemplate;

// graphQL CVEs query
export const pageQuery = graphql`
  query CvesPage($skip: Int!, $limit: Int!) {
    cves: allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { templateKey: { eq: "cve-template" } } }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 400)
          frontmatter {
            title
            slug
            date
            severity
          }
          fields {
            path
          }
        }
      }
    }
  }
`;
