import { faSquareRss } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";

const Head = () => (
  <div className="pt-5 pb-2">
    <h1 className="is-inline head-h1 is-size-4 has-text-weight-bold mr-6 mb-3">
      Project Reactor Security Advisories
    </h1>
    <div className="secondary-nav">
      <div className="rss-category">
        <Link to={"/security"} className="is-link">
          All advisories
        </Link>
        <Link to={"/security.atom"} className="is-link mr-5">
          <FontAwesomeIcon
            className="ml-1"
            icon={faSquareRss}
            style={{ width: 15 }}
          />
        </Link>
      </div>
    </div>
  </div>
);

export default Head;
